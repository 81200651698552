import Shikugun from "./ShikugunType";
import Todofuken from "./TodofukenType";
import { SHIKUGUN_GROUP_TABLE } from "~/data/SHIKUGUN_GROUP_TABLE";

type ShikugunGroupProps = {
  id: string | null;
  display_name: string;
  short_display_name: string | null;
  todofuken_id: string;
};

export default class ShikugunGroup {
  private readonly _id: string | null;
  private readonly _display_name: string;
  private readonly _short_display_name: string | null;
  private readonly _todofuken_id: string;

  // 初回生成時には作成しない変数
  private _todofuken: Todofuken | undefined;
  private _shikuguns: Shikugun[] | undefined;

  constructor(props: ShikugunGroupProps) {
    this._id = props.id;
    this._display_name = props.display_name;
    this._short_display_name = props.short_display_name;
    this._todofuken_id = props.todofuken_id;
  }

  get id(): string | null {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }
  get short_display_name(): string | null {
    return this._short_display_name;
  }

  get todofuken_id(): string {
    return this._todofuken_id;
  }

  get shikuguns(): Shikugun[] {
    if (!this._shikuguns) {
      this._shikuguns = Shikugun.findByTodofukenIdAndShikugunGroupId(this._todofuken_id, this.id);
    }
    return this._shikuguns;
  }

  get todofuken(): Todofuken {
    if (!this._todofuken) {
      this._todofuken = Todofuken.findStrictly(this.todofuken_id);
    }
    return this._todofuken;
  }

  /*
   * FIXME: API化した際のリプレイス対象ここから
   */
  static all(): ShikugunGroup[] {
    return SHIKUGUN_GROUP_TABLE.map((data) => new ShikugunGroup(data));
  }

  static find(id: string): ShikugunGroup | undefined {
    const target = SHIKUGUN_GROUP_TABLE.find((data) => data.id === id);
    return target ? new ShikugunGroup(target) : undefined;
  }

  static findStrictly(id: string): ShikugunGroup {
    const target = this.find(id);
    if (!target) {
      throw new TypeError(`shikugunGroupId ${id} is invalid.`);
    }
    return target;
  }

  static findByTodofukenID(todofuken_id: string): ShikugunGroup[] {
    return SHIKUGUN_GROUP_TABLE.filter((data) => data.todofuken_id === todofuken_id).map(
      (data) => new ShikugunGroup(data),
    );
  }

  /*
   * API化した際のリプレイス対象ここまで
   */
}
