import ShikugunGroup from "./ShikugunGroupType";
import Todofuken from "./TodofukenType";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";
import { SHIKUGUN_TABLE } from "~/data/SHIKUGUN_TABLE";

type ShikugunProps = {
  id: string;
  display_name: string;
  short_display_name: string | null;
  todofuken_id: string;
  shikugun_group_id: string | null;
};

export default class Shikugun {
  private readonly _id: string;
  private readonly _display_name: string;
  private readonly _short_display_name: string | null;
  private readonly _todofuken_id: string;
  private readonly _shikugun_group_id: string | null;

  // 初回生成時には作成しない変数
  private _todofuken: Todofuken | undefined;
  private _shikugun_group: ShikugunGroup | undefined;

  constructor(props: ShikugunProps) {
    this._id = props.id;
    this._display_name = props.display_name;
    this._short_display_name = props.short_display_name;
    this._todofuken_id = props.todofuken_id;
    this._shikugun_group_id = props.shikugun_group_id;
  }

  get id(): string {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }
  get short_display_name(): string | null {
    return this._short_display_name;
  }

  get todofuken_id(): string {
    return this._todofuken_id;
  }

  get shikugun_group_id(): string | null {
    return this._shikugun_group_id;
  }

  get todofuken(): Todofuken {
    if (!this._todofuken) {
      this._todofuken = Todofuken.findStrictly(this.todofuken_id);
    }
    return this._todofuken;
  }

  get shikugun_group(): ShikugunGroup | undefined {
    if (!this._shikugun_group && this.shikugun_group_id) {
      this._shikugun_group = ShikugunGroup.findStrictly(this.shikugun_group_id);
    }
    return this._shikugun_group;
  }

  get url(): string {
    return `/list?${QUERY_KEY.SHIKUGUN}=d-${this.id}`;
  }

  /*
   * FIXME: API化した際のリプレイス対象ここから
   */
  static activeAll(): Shikugun[] {
    return SHIKUGUN_TABLE.filter((data) => data.is_active === true).map(
      (data) => new Shikugun(data),
    );
  }

  static activeAllIds(): string[] {
    return SHIKUGUN_TABLE.filter((d) => d.is_active).map((d) => d.id);
  }

  static find(id: string): Shikugun | undefined {
    const target = SHIKUGUN_TABLE.find((data) => data.id === id);
    return target ? new Shikugun(target) : undefined;
  }

  static findStrictly(id: string): Shikugun {
    const target = this.find(id);
    if (!target) {
      throw new TypeError(`shikugunId ${id} is invalid.`);
    }
    return target;
  }

  static findActiveByTodofukenId(todofuken_id: string): Shikugun[] {
    return SHIKUGUN_TABLE.filter(
      (data) => data.todofuken_id === todofuken_id && data.is_active,
    ).map((data) => new Shikugun(data));
  }

  static findActiveShikugunsByGroupId(shikugun_group_id: string): Shikugun[] {
    return SHIKUGUN_TABLE.filter(
      (data) => data.shikugun_group_id === shikugun_group_id && data.is_active,
    ).map((data) => new Shikugun(data));
  }

  static findByTodofukenIdAndShikugunGroupId(
    todofuken_id: string,
    shikugun_group_id: string | null,
  ): Shikugun[] {
    return SHIKUGUN_TABLE.filter(
      (data) =>
        data.todofuken_id === todofuken_id &&
        data.shikugun_group_id === shikugun_group_id &&
        data.is_active,
    ).map((data) => new Shikugun(data));
  }

  static findShikugunGroupId(shikugun_group_id: string): Shikugun[] {
    return SHIKUGUN_TABLE.filter(
      (data) => data.shikugun_group_id === shikugun_group_id && data.is_active,
    ).map((data) => new Shikugun(data));
  }

  static findByTodofukenIdExceptShikugunGroup(todofuken_id: string): Shikugun[] {
    return SHIKUGUN_TABLE.filter(
      (data) =>
        data.todofuken_id === todofuken_id && data.is_active && data.shikugun_group_id === null,
    ).map((data) => new Shikugun(data));
  }
  /*
   * API化した際のリプレイス対象ここまで
   */

  /*
   * FIXME: ID検索化した際のリプレイス対象
   * ※ リプレイスしやすいように、find_by_nameは作らない
   */
  static convertNameToId(display_name: string): string | undefined {
    // NOTE: 下記より、関東圏外に進出するまでは都道府県名を参照する必要ないと思われる（し、そこまでにAPI参照してないとやばい）
    // 全国で表記が同一の市名
    // ・ 広島県の府中市と東京都の府中市（どちらも読みは「ふちゅう」）
    // ・ 北海道の伊達市と福島県の伊達市（どちらも読みは「だて」）
    // 全国で表記が同一の区名
    // ・ あるが、東京以外は●●市●●区表記なので実際には重複しない
    // 全国で表記が同一の郡名
    // ・ 広島・山口・鹿児島・北海道・愛知・滋賀・徳島・新潟・大阪にしかない。
    return SHIKUGUN_TABLE.find((data) => data.display_name === display_name)?.id;
  }
}
