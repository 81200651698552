// API化した場合における、沿線カテゴリテーブルに該当

export type LineCategorySchema = {
  id: string; // FIXME: 適切なID体系の採用（現在は便宜上与えている）
  display_name: string;
  is_active: boolean; // 現在はtrue固定だが、マスタ運用をし始めるとfalseが必要になる見込み
};

export const LINE_CATEGORY_TABLE: LineCategorySchema[] = [
  {
    id: "JR",
    display_name: "ＪＲ",
    is_active: true,
  },
  {
    id: "TokyoMetro",
    display_name: "東京メトロ",
    is_active: true,
  },
  {
    id: "Seibu",
    display_name: "西武鉄道",
    is_active: true,
  },
  {
    id: "Tobu",
    display_name: "東武鉄道",
    is_active: true,
  },
  {
    id: "Tokyu",
    display_name: "東急電鉄",
    is_active: true,
  },
  {
    id: "Toei",
    display_name: "都営地下鉄",
    is_active: true,
  },
  {
    id: "Keio",
    display_name: "京王電鉄",
    is_active: true,
  },
  {
    id: "Keisei",
    display_name: "京成電鉄",
    is_active: true,
  },
  {
    id: "Keihin",
    display_name: "京浜急行電鉄",
    is_active: true,
  },
  {
    id: "Odakyu",
    display_name: "小田急電鉄",
    is_active: true,
  },
  {
    id: "Sotetsu",
    display_name: "相模鉄道",
    is_active: true,
  },
  {
    id: "TamaUrbanMonorail",
    display_name: "多摩都市モノレール",
    is_active: true,
  },
  {
    id: "TokyoRinkai",
    display_name: "東京臨海高速鉄道",
    is_active: true,
  },
  {
    id: "Hokuso",
    display_name: "北総鉄道",
    is_active: true,
  },
  {
    id: "TokyoMonorail",
    display_name: "東京モノレール",
    is_active: true,
  },
  {
    id: "Yurikamome",
    display_name: "ゆりかもめ",
    is_active: true,
  },
  {
    id: "TsukubaExpress",
    display_name: "つくばエクスプレス",
    is_active: true,
  },
  {
    id: "NaritaSkyAccess",
    display_name: "成田スカイアクセス",
    is_active: true,
  },
  {
    id: "YokohamaMunicipalSubway",
    display_name: "横浜市営地下鉄",
    is_active: true,
  },
  {
    id: "YokohamaMinatomirai",
    display_name: "横浜高速鉄道",
    is_active: true,
  },
  {
    id: "Seasideline",
    display_name: "横浜新都市交通",
    is_active: true,
  },
  {
    id: "Enoshima",
    display_name: "江ノ島電鉄",
    is_active: true,
  },
  {
    id: "ShonanMonorail",
    display_name: "湘南モノレール",
    is_active: true,
  },
  {
    id: "HakoneTozan",
    display_name: "箱根登山鉄道",
    is_active: true,
  },
  {
    id: "IzuHakone",
    display_name: "伊豆箱根鉄道",
    is_active: true,
  },
];
