import { LINE_TABLE, LineSchema } from "~/data/LINE_TABLE";
import { STATION_TABLE } from "~/data/STATION_TABLE";

type CanonicalStationMapValue = {
  id: string;
  display_name: string;
  line_id: string;
  station_id: string;
  is_active: true;
};

/**
 * 重複している駅については、主要な駅に統一する
 *
 * key: 重複している駅ID
 * value: 統合先の駅ID
 *
 * NOTE:
 *   根本対応をするまでの暫定対応
 *   サポート地域を拡大する際には更新が必要
 */
const duplicateStationMapping: Record<string, string> = {
  "739": "5015", // 海老名
  "4819": "6441", // 豊島園
  "6472": "6349", // 早稲田
  "6668": "5162", // 弘明寺
  "7080": "5169", // 金沢八景
  "10087": "10086", // 羽田空港第３ターミナル
  "4973": "9180", // 多摩動物公園
};

/**
 * 正規化された駅クラス
 */
export class CanonicalStation {
  private static _canonicalStationMap?: Map<string, CanonicalStationMapValue>;

  /**
   * 正規化された駅IDを返す
   *
   * @param stationId station_id
   */
  static canonicalId(stationId: string): string | undefined {
    const targetId = duplicateStationMapping[stationId] || stationId;
    return this.canonicalStationMap().get(targetId)?.id;
  }

  /**
   * 正規化された駅ID一覧を返す
   */
  static canonicalIds(): string[] {
    return Array.from(this.canonicalStationMap().values()).map((s) => s.id);
  }

  /**
   * 指定した沿線に含まれる正規化された駅ID一覧を返す
   */
  static canonicalIdsInLine(lineId: string): string[] {
    return Array.from(this.canonicalStationMap().values())
      .filter((s) => s.line_id === lineId)
      .map((s) => s.id);
  }

  /**
   * 正規化された駅データのマッピングを返す
   *
   * key: station_id
   */
  private static canonicalStationMap(): Map<string, CanonicalStationMapValue> {
    if (!this._canonicalStationMap) {
      this._canonicalStationMap = this.generateCanonicalStationMap();
    }
    return this._canonicalStationMap;
  }

  /**
   * 正規化された駅データのマッピングを生成する
   */
  private static generateCanonicalStationMap(): Map<string, CanonicalStationMapValue> {
    const map = new Map<string, CanonicalStationMapValue>();
    const duplicateStationIds = Object.keys(duplicateStationMapping);

    for (const item of STATION_TABLE) {
      // 重複している駅の場合は登録をスキップする
      if (duplicateStationIds.includes(item.station_id)) {
        continue;
      }

      if (map.has(item.station_id)) {
        const preItem = map.get(item.station_id) as CanonicalStationMapValue;
        const preItemLine = LINE_TABLE.find((l) => l.id === preItem.line_id) as LineSchema;
        const curItemLine = LINE_TABLE.find((l) => l.id === item.line_id) as LineSchema;

        const canonical = preItemLine.order < curItemLine.order ? preItem : item;
        map.set(item.station_id, canonical);
      } else {
        map.set(item.station_id, item);
      }
    }
    return map;
  }
}
