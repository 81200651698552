import Line from "./LineType";
import { LINE_CATEGORY_TABLE } from "~/data/LINE_CATEGORY_TABLE";

type LineCategoryProps = {
  id: string;
  display_name: string;
};

export default class LineCategory {
  private readonly _id: string;
  private readonly _display_name: string;

  // 初回生成時には作成しない変数
  private _lines: Line[] | undefined;

  constructor(props: LineCategoryProps) {
    this._id = props.id;
    this._display_name = props.display_name;
  }

  get id(): string {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }

  get lines(): Line[] {
    if (!this._lines) {
      this._lines = Line.findByLineCategoryID(this.id);
    }
    return this._lines;
  }

  /*
   * FIXME: API化した際のリプレイス対象ここから
   */
  // すべての沿線カテゴリのうち、アクティブなものを返す
  static all(): LineCategory[] {
    return LINE_CATEGORY_TABLE.filter((data) => data.is_active === true).map(
      (data) => new LineCategory(data),
    );
  }

  // 特定のIDをもつ沿線カテゴリを返す
  static find(id: string): LineCategory | undefined {
    const target = LINE_CATEGORY_TABLE.find((data) => data.id === id);
    return target ? new LineCategory(target) : undefined;
  }

  // 特定のIDをもつ沿線カテゴリを返す。なければエラーを投げる。
  static findStrictly(id: string): LineCategory {
    const target = this.find(id);
    if (!target) {
      throw new TypeError(`lineCategoryId ${id} is invalid.`);
    }
    return target;
  }

  /*
   * API化した際のリプレイス対象ここまで
   */
}
