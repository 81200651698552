import LineCategory from "./LineCategoryType";
import Station from "./StationType";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";
import { LINE_TABLE } from "~/data/LINE_TABLE";

type LineProps = {
  id: string;
  display_name: string;
  color?: string;
  order: number;
  line_category_id: string;
  major_stations?: string[];
};

export default class Line {
  private readonly _id: string;
  private readonly _display_name: string;
  private readonly _color: string;
  private readonly _order: number;
  private readonly _line_category_id: string;
  private readonly _major_stations: string[];

  // 初回生成時には作成しない変数
  private _line_category: LineCategory | undefined;
  private _line_categories: LineCategory[] | undefined;
  private _stations: Station[] | undefined;

  constructor(props: LineProps) {
    this._id = props.id;
    this._display_name = props.display_name;
    this._color = props.color || "#C8C8C8";
    this._order = props.order;
    this._line_category_id = props.line_category_id;
    this._major_stations = props.major_stations;
  }

  get id(): string {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }

  // 一部の社名を省略した短縮名（人気のエリア用）
  get short_display_name(): string {
    return ["ＪＲ", "東京メトロ", "東急", "宇須"].reduce(
      (ret, cur) => ret.replace(cur, ""),
      this._display_name,
    );
  }

  get color(): string {
    return this._color;
  }

  get order(): number {
    return this._order;
  }

  get stations(): Station[] {
    if (!this._stations) {
      this._stations = Station.findByLineID(this.id);
    }
    return this._stations;
  }

  get line_category(): LineCategory {
    if (!this._line_category) {
      this._line_category = LineCategory.findStrictly(this._line_category_id);
    }
    return this._line_category;
  }

  get line_categories(): LineCategory[] {
    if (!this._line_category) {
      this._line_category = LineCategory.findStrictly(this._line_category_id);
    }
    return this._line_categories;
  }

  get major_stations(): string[] {
    return this._major_stations;
  }

  get url(): string {
    return `/list?${QUERY_KEY.LINE}=d-${this.id}`;
  }

  /*
   * FIXME: API化した際のリプレイス対象ここから
   */
  // すべての沿線のうち、アクティブなものを返す
  static all(): Line[] {
    return LINE_TABLE.filter((data) => data.is_active === true).map((data) => new Line(data));
  }

  // アクティブな沿線のID一覧を返す
  static allIds(): string[] {
    return LINE_TABLE.filter((d) => d.is_active).map((d) => d.id);
  }

  // 特定のIDをもつ沿線を返す(アクティブかどうかは問わない)
  static find(id: string): Line | undefined {
    const target = LINE_TABLE.find((data) => data.id === id);
    return target ? new Line(target) : undefined;
  }

  // 特定のIDをもつ沿線を返す。なければエラーを投げる(アクティブかどうかは問わない)
  static findStrictly(id: string): Line {
    const target = this.find(id);
    if (!target) {
      throw new TypeError(`lineId ${id} is invalid.`);
    }
    return target;
  }

  // 特定の沿線カテゴリに属する市区郡のうち、アクティブなものを返す
  static findByLineCategoryID(line_id: string): Line[] {
    return LINE_TABLE.filter((data) => data.line_category_id === line_id && data.is_active).map(
      (data) => new Line(data),
    );
  }

  /*
   * API化した際のリプレイス対象ここまで
   */

  /*
   * FIXME: ID検索化した際のリプレイス対象
   * ※ リプレイスしやすいように、find_by_nameは作らない
   */
  static convertNameToID(display_name: string): string | undefined {
    return LINE_TABLE.find((data) => data.display_name === display_name)?.id;
  }
}
