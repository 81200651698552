// API化した場合における、都道府県テーブルに該当

export type LineSchema = {
  id: string;
  display_name: string;
  color?: string;
  order: number;
  line_category_id: string;
  is_active: boolean; // 現在はtrue固定だが、マスタ運用をし始めるとfalseが必要になる見込み
  major_stations?: string[];
};

export const LINE_TABLE: LineSchema[] = [
  {
    id: "89",
    display_name: "ＪＲ東海道本線",
    order: 110,
    color: "#FF7E1C",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "新橋", "品川"],
  },
  {
    id: "91",
    display_name: "ＪＲ山手線",
    order: 101,
    color: "#85C023",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "渋谷", "新宿", "上野", "神田"],
  },
  {
    id: "93",
    display_name: "ＪＲ京浜東北・根岸線",
    order: 104,
    color: "#00A7E3",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["赤羽", "上野", "東京", "新橋", "蒲田"],
  },
  {
    id: "94",
    display_name: "ＪＲ横須賀線",
    order: 108,
    color: "#0074BE",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "新橋", "品川", "西大井"],
  },
  {
    id: "95",
    display_name: "ＪＲ埼京線",
    order: 107,
    color: "#00AC84",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["西大井", "渋谷", "新宿", "池袋", "浮間舟渡"],
  },
  {
    id: "99",
    display_name: "ＪＲ南武線",
    order: 109,
    color: "#FFE400",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["矢野口", "稲城長沼", "府中本町", "分倍河原", "立川"],
  },
  {
    id: "100",
    display_name: "ＪＲ鶴見線",
    order: 111,
    color: "#FFE500",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["鶴見", "国道", "鶴見小野", "浜川崎", "海芝浦"],
  },
  {
    id: "103",
    display_name: "ＪＲ武蔵野線",
    order: 118,
    line_category_id: "JR",
    is_active: true,
    major_stations: ["府中本町", "北府中", "西国分寺", "新小平", "新秋津"],
  },
  {
    id: "105",
    display_name: "ＪＲ横浜線",
    order: 112,
    color: "#85C023",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["八王子", "片倉", "相原", "町田", "成瀬"],
  },
  {
    id: "107",
    display_name: "ＪＲ相模線",
    order: 113,
    color: "#008689",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["茅ヶ崎", "北茅ヶ崎", "香川", "厚木", "橋本"],
  },
  {
    id: "110",
    display_name: "ＪＲ中央線",
    order: 102,
    color: "#EB5C01",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "飯田橋", "市ヶ谷", "新宿", "高尾"],
  },
  {
    id: "114",
    display_name: "ＪＲ中央本線",
    order: 103,
    color: "#0071C5",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "飯田橋", "市ヶ谷", "新宿", "高尾"],
  },
  {
    id: "115",
    display_name: "ＪＲ青梅線",
    order: 114,
    color: "#EB5C01",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["立川", "西立川", "東中神", "拝島", "奥多摩"],
  },
  {
    id: "116",
    display_name: "ＪＲ五日市線",
    order: 115,
    color: "#EB5C01",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["拝島", "熊川", "東秋留", "秋川", "武蔵五日市"],
  },
  {
    id: "117",
    display_name: "ＪＲ八高線",
    order: 116,
    color: "#A09D95",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["八王子", "北八王子", "小宮", "拝島", "箱根ヶ崎"],
  },
  {
    id: "129",
    display_name: "ＪＲ常磐線",
    order: 117,
    color: "#00C18A",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["品川", "新橋", "東京", "上野", "金町"],
  },
  {
    id: "137",
    display_name: "ＪＲ高崎線",
    order: 119,
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "上野", "尾久", "赤羽"],
  },
  {
    id: "194",
    display_name: "ＪＲ総武線",
    order: 105,
    color: "#FFE500",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["小岩", "飯田橋", "市ヶ谷", "新宿", "三鷹"],
  },
  {
    id: "196",
    display_name: "ＪＲ総武本線",
    order: 106,
    color: "#FDD700",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "新日本橋", "馬喰町", "錦糸町", "新小岩"],
  },
  {
    id: "199",
    display_name: "ＪＲ京葉線",
    order: 120,
    line_category_id: "JR",
    is_active: true,
    major_stations: ["東京", "八丁堀", "越中嶋", "新木場", "葛西臨海公園"],
  },
  {
    id: "212",
    display_name: "ＪＲ御殿場線",
    order: 121,
    line_category_id: "JR",
    is_active: true,
    major_stations: ["駿河小山", "足柄", "御殿場", "裾野", "沼津"],
  },
  {
    id: "429",
    display_name: "西武池袋線",
    order: 303,
    color: "#EE7A00",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["池袋", "椎名町", "南長崎", "練馬", "秋津"],
  },
  {
    id: "434",
    display_name: "西武有楽町線",
    order: 301,
    color: "#EE7A00",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["小竹向原", "新桜台", "練馬"],
  },
  {
    id: "435",
    display_name: "西武豊島線",
    order: 309,
    color: "#EE7A00",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["練馬", "豊島園"],
  },
  {
    id: "437",
    display_name: "西武新宿線",
    order: 302,
    color: "#00A6BF",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["西武新宿", "高田馬場", "中井", "小平", "東村山"],
  },
  {
    id: "440",
    display_name: "西武拝島線",
    order: 304,
    color: "#00A6BF",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["小平", "萩山", "小川", "玉川上水", "拝島"],
  },
  {
    id: "442",
    display_name: "西武多摩湖線",
    order: 307,
    color: "#F7AF0E",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["国分寺", "一橋学園", "萩山", "武蔵大和", "多摩湖"],
  },
  {
    id: "443",
    display_name: "西武国分寺線",
    order: 305,
    color: "#1EAD4C",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["国分寺", "恋ヶ窪", "鷹の台", "小川", "東村山"],
  },
  {
    id: "444",
    display_name: "西武西武園線",
    order: 308,
    color: "#1EAD4C",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["東村山", "西武園"],
  },
  {
    id: "446",
    display_name: "西武多摩川線",
    order: 306,
    color: "#EF7A00",
    line_category_id: "Seibu",
    is_active: true,
    major_stations: ["武蔵境", "新小金井", "多磨", "白糸台", "是政"],
  },
  {
    id: "576",
    display_name: "東京メトロ銀座線",
    order: 202,
    color: "#FF9500",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["浅草", "上野", "神田", "新橋", "渋谷"],
  },
  {
    id: "577",
    display_name: "東京メトロ丸ノ内線",
    order: 203,
    color: "#F62E36",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["池袋", "四ツ谷", "新宿", "方南町"],
  },
  {
    id: "579",
    display_name: "東京メトロ日比谷線",
    order: 204,
    color: "#B5B5AC",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["北千住", "上野", "秋葉原", "恵比寿", "中目黒"],
  },
  {
    id: "580",
    display_name: "東京メトロ東西線",
    order: 205,
    color: "#009BBF",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["中野", "高田馬場", "飯田橋", "大手町", "葛西"],
  },
  {
    id: "582",
    display_name: "東京メトロ千代田線",
    order: 206,
    color: "#00BB85",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["北綾瀬", "北千住", "西日暮里", "大手町", "代々木上原"],
  },
  {
    id: "583",
    display_name: "東京メトロ有楽町線",
    order: 207,
    color: "#C1A470",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["地下鉄成増", "池袋", "飯田橋", "市ヶ谷", "新木場"],
  },
  {
    id: "584",
    display_name: "東京メトロ半蔵門線",
    order: 208,
    color: "#8F76D6",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["渋谷", "青山一丁目", "大手町", "錦糸町", "押上"],
  },
  {
    id: "585",
    display_name: "東京メトロ南北線",
    order: 209,
    color: "#00AC9B",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["赤羽岩淵", "飯田橋", "市ヶ谷", "四ツ谷", "目黒"],
  },
  {
    id: "586",
    display_name: "都営浅草線",
    order: 601,
    color: "#E14131",
    line_category_id: "Toei",
    is_active: true,
    major_stations: ["西馬込", "五反田", "新橋", "浅草", "押上"],
  },
  {
    id: "587",
    display_name: "都営三田線",
    order: 602,
    color: "#006CB6",
    line_category_id: "Toei",
    is_active: true,
    major_stations: ["目黒", "日比谷", "大手町", "水道橋", "西高島平"],
  },
  {
    id: "588",
    display_name: "都営新宿線",
    order: 603,
    color: "#B0C124",
    line_category_id: "Toei",
    is_active: true,
    major_stations: ["新宿", "新宿三丁目", "市ヶ谷", "九段下", "篠崎"],
  },
  {
    id: "589",
    display_name: "都営大江戸線",
    order: 604,
    color: "#C6035D",
    line_category_id: "Toei",
    is_active: true,
    major_stations: ["新宿西口", "飯田橋", "代々木", "新宿", "光が丘"],
  },
  {
    id: "590",
    display_name: "都電荒川線",
    order: 605,
    color: "#269A45",
    line_category_id: "Toei",
    is_active: true,
    major_stations: ["三ノ輪橋", "荒川区役所前", "荒川二丁目", "熊野前", "早稲田"],
  },
  {
    id: "604",
    display_name: "横浜市営地下鉄ブルーライン",
    order: 1902,
    color: "#0070C0",
    line_category_id: "YokohamaMunicipalSubway",
    is_active: true,
    major_stations: ["あざみ野", "新横浜", "横浜", "戸塚", "湘南台"],
  },
  {
    id: "629",
    display_name: "箱根登山鉄道",
    order: 2401,
    line_category_id: "HakoneTozan",
    is_active: true,
    major_stations: ["小田原", "箱根板橋", "風祭", "入生田", "強羅"],
  },
  {
    id: "659",
    display_name: "金沢シーサイドライン",
    order: 2101,
    line_category_id: "Seasideline",
    is_active: true,
    major_stations: ["新杉田", "南部市場", "鳥浜", "並木北", "金沢八景"],
  },
  {
    id: "715",
    display_name: "北総鉄道",
    order: 1401,
    line_category_id: "Hokuso",
    is_active: true,
    major_stations: ["京成高砂", "新柴又"],
  },
  {
    id: "720",
    display_name: "東京モノレール",
    order: 1501,
    color: "#003586",
    line_category_id: "TokyoMonorail",
    is_active: true,
    major_stations: [
      "浜松町",
      "天王洲アイル",
      "天空橋",
      "羽田空港第1ターミナル",
      "羽田空港第2ターミナル",
    ],
  },
  {
    id: "721",
    display_name: "湘南モノレール",
    order: 2301,
    color: "#C8C8C8",
    line_category_id: "ShonanMonorail",
    is_active: true,
    major_stations: ["大船", "富士見町", "湘南町屋", "湘南深沢", "湘南江の島"],
  },
  {
    id: "722",
    display_name: "江ノ島電鉄",
    order: 2201,
    color: "#C8C8C8",
    line_category_id: "Enoshima",
    is_active: true,
    major_stations: ["鎌倉", "和田塚", "由比ヶ浜", "長谷", "藤沢"],
  },
  {
    id: "723",
    display_name: "伊豆箱根鉄道大雄山線",
    order: 2501,
    color: "#007AC1",
    line_category_id: "IzuHakone",
    is_active: true,
    major_stations: ["小田原", "緑町", "井細田", "五百羅漢", "大雄山"],
  },
  {
    id: "724",
    display_name: "伊豆箱根鉄道駿豆線",
    order: 2502,
    color: "#007AC1",
    line_category_id: "IzuHakone",
    is_active: true,
    major_stations: ["三島", "三島広小路", "三島田町", "三島二日町", "修繕寺"],
  },
  {
    id: "830",
    display_name: "東武伊勢崎線",
    order: 402,
    color: "#E72019",
    line_category_id: "Tobu",
    is_active: true,
    major_stations: ["浅草", "押上", "曳舟", "北千住", "竹ノ塚"],
  },
  {
    id: "843",
    display_name: "東武亀戸線",
    order: 403,
    color: "#226BB8",
    line_category_id: "Tobu",
    is_active: true,
    major_stations: ["曳舟", "小村井", "東あずま", "亀戸水神", "亀戸"],
  },
  {
    id: "844",
    display_name: "東武大師線",
    order: 404,
    color: "#226BB8",
    line_category_id: "Tobu",
    is_active: true,
    major_stations: ["西新井", "大師前"],
  },
  {
    id: "848",
    display_name: "東武東上線",
    order: 401,
    color: "#10428E",
    line_category_id: "Tobu",
    is_active: true,
    major_stations: ["池袋", "北池袋", "下板橋", "大山", "成増"],
  },
  {
    id: "855",
    display_name: "京成本線",
    order: 803,
    color: "#0166B3",
    line_category_id: "Keisei",
    is_active: true,
    major_stations: ["京成上野", "日暮里", "青舐", "京成高砂", "江戸川"],
  },
  {
    id: "858",
    display_name: "京成押上線",
    order: 804,
    color: "#0166B3",
    line_category_id: "Keisei",
    is_active: true,
    major_stations: ["押上", "京成曳舟", "八広", "青砥", "京成高砂"],
  },
  {
    id: "860",
    display_name: "京成金町線",
    order: 805,
    color: "#0166B3",
    line_category_id: "Keisei",
    is_active: true,
    major_stations: ["京成高砂", "柴又", "京成金町"],
  },
  {
    id: "863",
    display_name: "京王線",
    order: 701,
    color: "#C60076",
    line_category_id: "Keio",
    is_active: true,
    major_stations: ["新宿", "明大前", "分倍河原", "高幡不動", "京成八王子"],
  },
  {
    id: "864",
    display_name: "京王高尾線",
    order: 704,
    color: "#C60076",
    line_category_id: "Keio",
    is_active: true,
    major_stations: ["北野", "京王片倉", "山田", "高尾", "高尾山口"],
  },
  {
    id: "865",
    display_name: "京王相模原線",
    order: 703,
    color: "#C60076",
    line_category_id: "Keio",
    is_active: true,
    major_stations: ["調布", "京王多摩川", "京王よみうりランド", "稲城", "多摩境"],
  },
  {
    id: "869",
    display_name: "京王競馬場線",
    order: 705,
    line_category_id: "Keio",
    is_active: true,
    major_stations: ["東府中", "府中競馬正門前"],
  },
  {
    id: "870",
    display_name: "京王動物園線",
    order: 706,
    line_category_id: "Keio",
    is_active: true,
    major_stations: ["高畑不動", "多摩動物公園"],
  },
  {
    id: "871",
    display_name: "京王井の頭線",
    order: 702,
    color: "#004385",
    line_category_id: "Keio",
    is_active: true,
    major_stations: ["渋谷", "神泉", "下北沢", "明大前", "吉祥寺"],
  },
  {
    id: "873",
    display_name: "小田急小田原線",
    order: 1001,
    color: "#0085CE",
    line_category_id: "Odakyu",
    is_active: true,
    major_stations: ["新宿", "南新宿", "代々木上原", "下北沢", "町田"],
  },
  {
    id: "877",
    display_name: "小田急江ノ島線",
    order: 1002,
    color: "#0085CE",
    line_category_id: "Odakyu",
    is_active: true,
    major_stations: ["相模大野", "中央林間", "湘南台", "藤沢", "片瀬江ノ島"],
  },
  {
    id: "879",
    display_name: "小田急多摩線",
    order: 1003,
    color: "#0085CE",
    line_category_id: "Odakyu",
    is_active: true,
    major_stations: ["小田急永山", "小田急多摩センター", "唐木田"],
  },
  {
    id: "882",
    display_name: "東急東横線",
    order: 501,
    color: "#DA0042",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["渋谷", "中目黒", "自由が丘", "田園調布", "多摩川"],
  },
  {
    id: "885",
    display_name: "東急田園都市線",
    order: 502,
    color: "#00AA8D",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["渋谷", "池尻大橋", "三軒茶屋", "二子玉川", "南町田グランベリーパーク"],
  },
  {
    id: "889",
    display_name: "東急目黒線",
    order: 503,
    color: "#009CD3",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["目黒", "不動前", "大岡山", "田園調布", "多摩川"],
  },
  {
    id: "890",
    display_name: "東急大井町線",
    order: 504,
    color: "#F18C43",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["大井町", "中延", "大岡山", "自由ヶ丘", "二子玉川"],
  },
  {
    id: "891",
    display_name: "東急池上線",
    order: 505,
    color: "#EE86A8",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["五反田", "大崎広小路", "戸越銀座", "旗の台", "蒲田"],
  },
  {
    id: "892",
    display_name: "東急こどもの国線",
    order: 508,
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["長津田", "恩田", "こどもの国"],
  },
  {
    id: "893",
    display_name: "東急世田谷線",
    order: 506,
    color: "#FCC800",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["三軒茶屋", "西太子堂", "若林", "松陰神社前", "下高井戸"],
  },
  {
    id: "894",
    display_name: "京急本線",
    order: 901,
    color: "#0096E0",
    line_category_id: "Keihin",
    is_active: true,
    major_stations: ["泉岳寺", "品川", "北品川", "京急蒲田", "六郷土手"],
  },
  {
    id: "895",
    display_name: "京急久里浜線",
    order: 906,
    line_category_id: "Keihin",
    is_active: true,
    major_stations: ["堀ノ内", "新大津", "北久里浜", "京急久里浜", "三崎口"],
  },
  {
    id: "896",
    display_name: "京急逗子線",
    order: 907,
    line_category_id: "Keihin",
    is_active: true,
    major_stations: ["金澤八景", "六浦", "神武寺", "逗子", "葉山"],
  },
  {
    id: "900",
    display_name: "京急空港線",
    order: 902,
    color: "#0096E0",
    line_category_id: "Keihin",
    is_active: true,
    major_stations: ["京急蒲田", "糀谷", "大鳥居", "天空橋", "羽田空港第1・第2ターミナル"],
  },
  {
    id: "901",
    display_name: "京急大師線",
    order: 908,
    line_category_id: "Keihin",
    is_active: true,
    major_stations: ["京急川崎", "港町", "鈴木町", "川崎大師", "小島新田"],
  },
  {
    id: "902",
    display_name: "相鉄いずみ野線",
    order: 1102,
    line_category_id: "Sotetsu",
    is_active: true,
    major_stations: ["二俣川", "南万騎が原", "緑園都市", "弥生台", "湘南台"],
  },
  {
    id: "903",
    display_name: "相鉄本線",
    order: 1101,
    color: "#0073BC",
    line_category_id: "Sotetsu",
    is_active: true,
    major_stations: ["横浜", "西谷", "二俣川", "大和", "海老名"],
  },
  {
    id: "1000",
    display_name: "多摩都市モノレール",
    order: 1201,
    color: "#FF963F",
    line_category_id: "TamaUrbanMonorail",
    is_active: true,
    major_stations: ["多摩センター", "松が谷", "高幡不動", "玉川上水", "上北台"],
  },
  {
    id: "1001",
    display_name: "新交通ゆりかもめ",
    order: 1601,
    color: "#0065A6",
    line_category_id: "Yurikamome",
    is_active: true,
    major_stations: ["新橋", "汐留", "竹芝", "日の出", "豊洲"],
  },
  {
    id: "1002",
    display_name: "東京臨海高速鉄道りんかい線",
    order: 1301,
    color: "#00418E",
    line_category_id: "TokyoRinkai",
    is_active: true,
    major_stations: ["新木場", "東雲", "天王洲アイル", "大井町", "大崎"],
  },
  {
    id: "1013",
    display_name: "東急多摩川線",
    order: 507,
    color: "#AE0079",
    line_category_id: "Tokyu",
    is_active: true,
    major_stations: ["多摩川", "沼部", "鵜の木", "新丸子", "蒲田"],
  },
  {
    id: "2013",
    display_name: "みなとみらい線",
    order: 2001,
    line_category_id: "YokohamaMinatomirai",
    is_active: true,
    major_stations: ["横浜", "新高島", "みなとみらい", "馬車道", "元町・中華街"],
  },
  {
    id: "2021",
    display_name: "つくばエクスプレス",
    order: 1701,
    line_category_id: "TsukubaExpress",
    is_active: true,
    major_stations: ["秋葉原", "浅草", "南千住", "北千住", "六町"],
  },
  {
    id: "2029",
    display_name: "日暮里舎人ライナー",
    order: 606,
    line_category_id: "Toei",
    is_active: true,
    major_stations: ["日暮里", "西日暮里", "赤土小学校前", "熊野前", "見沼代親水公園"],
  },
  {
    id: "2030",
    display_name: "横浜市営地下鉄グリーンライン",
    order: 1901,
    color: "#00B050",
    line_category_id: "YokohamaMunicipalSubway",
    is_active: true,
    major_stations: ["中山", "川和町", "センター南", "センター北", "日吉"],
  },
  {
    id: "2032",
    display_name: "東京メトロ副都心線",
    order: 201,
    color: "#9C5E31",
    line_category_id: "TokyoMetro",
    is_active: true,
    major_stations: ["地下鉄成増", "小竹向原", "池袋", "新宿三丁目", "渋谷"],
  },
  {
    id: "2035",
    display_name: "湘南新宿ライン宇須",
    order: 122,
    color: "#DE0515",
    line_category_id: "JR",
    is_active: true,
    major_stations: ["赤羽", "池袋", "新宿", "渋谷", "西大井"],
  },
  {
    id: "2036",
    display_name: "湘南新宿ライン高海",
    order: 123,
    line_category_id: "JR",
    is_active: true,
    major_stations: ["赤羽", "池袋", "新宿", "渋谷", "大崎"],
  },
  {
    id: "2038",
    display_name: "成田スカイアクセス",
    order: 1801,
    color: "#F47B21",
    line_category_id: "NaritaSkyAccess",
    is_active: true,
    major_stations: ["京成上野", "日暮里", "青砥", "京成高砂"],
  },
  {
    id: "2047",
    display_name: "相鉄新横浜線",
    order: 1103,
    line_category_id: "Sotetsu",
    is_active: true,
    major_stations: ["新横浜", "羽沢横浜国大", "西谷"],
  },
];
