// API化した場合における、市区郡テーブルに該当

import { ShikugunGroup } from "~/data/SHIKUGUN_GROUP_TABLE";
import { Todofuken } from "~/data/TODOFUKEN_TABLE";

export type ShikugunSchema = {
  id: string; // マスタでは jis x 0402 を使用しておりSEOの懸念があるがどこかで統一したい
  display_name: string;
  short_display_name: string | null;
  todofuken_id: string;
  shikugun_group_id: string | null;
  is_active: boolean;
};

export const SHIKUGUN_TABLE: ShikugunSchema[] = [
  {
    id: "131016",
    display_name: "千代田区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131024",
    display_name: "中央区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131032",
    display_name: "港区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131041",
    display_name: "新宿区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131059",
    display_name: "文京区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131067",
    display_name: "台東区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131075",
    display_name: "墨田区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131083",
    display_name: "江東区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131091",
    display_name: "品川区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131105",
    display_name: "目黒区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131113",
    display_name: "大田区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131121",
    display_name: "世田谷区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131130",
    display_name: "渋谷区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131148",
    display_name: "中野区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131156",
    display_name: "杉並区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131164",
    display_name: "豊島区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131172",
    display_name: "北区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131181",
    display_name: "荒川区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131199",
    display_name: "板橋区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131202",
    display_name: "練馬区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131211",
    display_name: "足立区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131229",
    display_name: "葛飾区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "131237",
    display_name: "江戸川区",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: ShikugunGroup.Tokyo23ku,
    is_active: true,
  },
  {
    id: "132012",
    display_name: "八王子市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132021",
    display_name: "立川市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132039",
    display_name: "武蔵野市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132047",
    display_name: "三鷹市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132055",
    display_name: "青梅市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132063",
    display_name: "府中市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132071",
    display_name: "昭島市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132080",
    display_name: "調布市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132098",
    display_name: "町田市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132101",
    display_name: "小金井市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132110",
    display_name: "小平市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132128",
    display_name: "日野市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132136",
    display_name: "東村山市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132144",
    display_name: "国分寺市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132152",
    display_name: "国立市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132187",
    display_name: "福生市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132195",
    display_name: "狛江市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132209",
    display_name: "東大和市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132217",
    display_name: "清瀬市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132225",
    display_name: "東久留米市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132233",
    display_name: "武蔵村山市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132241",
    display_name: "多摩市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132250",
    display_name: "稲城市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132276",
    display_name: "羽村市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "132292",
    display_name: "西東京市",
    short_display_name: null,
    todofuken_id: Todofuken.Tokyo,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "141011",
    display_name: "横浜市鶴見区",
    short_display_name: "鶴見区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141020",
    display_name: "横浜市神奈川区",
    short_display_name: "神奈川区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141038",
    display_name: "横浜市西区",
    short_display_name: "西区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141046",
    display_name: "横浜市中区",
    short_display_name: "中区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141054",
    display_name: "横浜市南区",
    short_display_name: "南区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141062",
    display_name: "横浜市保土ケ谷区",
    short_display_name: "保土ケ谷区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141071",
    display_name: "横浜市磯子区",
    short_display_name: "磯子区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141089",
    display_name: "横浜市金沢区",
    short_display_name: "金沢区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141097",
    display_name: "横浜市港北区",
    short_display_name: "港北区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141101",
    display_name: "横浜市戸塚区",
    short_display_name: "戸塚区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141119",
    display_name: "横浜市港南区",
    short_display_name: "港南区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141127",
    display_name: "横浜市旭区",
    short_display_name: "旭区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141135",
    display_name: "横浜市緑区",
    short_display_name: "緑区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141143",
    display_name: "横浜市瀬谷区",
    short_display_name: "瀬谷区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141151",
    display_name: "横浜市栄区",
    short_display_name: "栄区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141160",
    display_name: "横浜市泉区",
    short_display_name: "泉区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141178",
    display_name: "横浜市青葉区",
    short_display_name: "青葉区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141186",
    display_name: "横浜市都筑区",
    short_display_name: "都筑区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Yokohama,
    is_active: true,
  },
  {
    id: "141313",
    display_name: "川崎市川崎区",
    short_display_name: "川崎区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141321",
    display_name: "川崎市幸区",
    short_display_name: "幸区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141330",
    display_name: "川崎市中原区",
    short_display_name: "中原区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141348",
    display_name: "川崎市高津区",
    short_display_name: "高津区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141356",
    display_name: "川崎市多摩区",
    short_display_name: "多摩区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141364",
    display_name: "川崎市宮前区",
    short_display_name: "宮前区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141372",
    display_name: "川崎市麻生区",
    short_display_name: "麻生区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Kawasaki,
    is_active: true,
  },
  {
    id: "141518",
    display_name: "相模原市緑区",
    short_display_name: "緑区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Sagamihara,
    is_active: true,
  },
  {
    id: "141526",
    display_name: "相模原市中央区",
    short_display_name: "中央区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Sagamihara,
    is_active: true,
  },
  {
    id: "141534",
    display_name: "相模原市南区",
    short_display_name: "市南区",
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: ShikugunGroup.Sagamihara,
    is_active: true,
  },
  {
    id: "142034",
    display_name: "平塚市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142042",
    display_name: "鎌倉市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142051",
    display_name: "藤沢市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142077",
    display_name: "茅ヶ崎市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142115",
    display_name: "秦野市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142123",
    display_name: "厚木市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142018",
    display_name: "横須賀市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "142069",
    display_name: "小田原市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "142085",
    display_name: "逗子市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142107",
    display_name: "三浦市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "142131",
    display_name: "大和市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142140",
    display_name: "伊勢原市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142158",
    display_name: "海老名市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142166",
    display_name: "座間市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142174",
    display_name: "南足柄市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "142182",
    display_name: "綾瀬市",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143014",
    display_name: "三浦郡葉山町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "143219",
    display_name: "高座郡寒川町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143413",
    display_name: "中郡大磯町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143421",
    display_name: "中郡二宮町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143618",
    display_name: "足柄上郡中井町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143626",
    display_name: "足柄上郡大井町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143634",
    display_name: "足柄上郡松田町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143642",
    display_name: "足柄上郡山北町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143669",
    display_name: "足柄上郡開成町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "143821",
    display_name: "足柄下郡箱根町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "143839",
    display_name: "足柄下郡真鶴町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "143847",
    display_name: "足柄下郡湯河原町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
  {
    id: "144011",
    display_name: "愛甲郡愛川町",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: true,
  },
  {
    id: "144029",
    display_name: "愛甲郡清川村",
    short_display_name: null,
    todofuken_id: Todofuken.Kanagawa,
    shikugun_group_id: null,
    is_active: false,
  },
];
